import { useAuthentications } from '@/app/context/Authentications';
import { Spinner } from '@nextui-org/react';
import styles from './mainLoading.module.css'; // Import SCSS module

const MainLoading = () => {
    const authentications = useAuthentications();

    return (
        <section
            className={`${styles.container} ${authentications.mainLoading ? styles.visible : styles.hidden
                }`}
        >
            <Spinner size="xl" color="primary" label="Loading..." />
        </section>
    );
};

export default MainLoading;
