"use client";

import { lazy, Suspense } from 'react';
import { usePathname } from "next/navigation";
import { ScrollShadow } from "@nextui-org/react";

const NavBar = lazy(() => import('@/components/navBar/navBar'));
const BottomBar = lazy(() => import('@/components/bottomBar/bottomBar'));
const Footer = lazy(() => import('@/components/footer/footer'));
import BottomBarSkeleton from '@/components/bottomBar/bottomBarSkeleton'; // Direct import
import AgeVerificationPopup from '@/components/ageVerificationPopup/ageVerificationPopup';
import styles from '@/components/uiProviders.module.css'; // Update to .module.scss
import NavBarSkeleton from '@/components/navBar/navBarSkeleton';

export function UIProviders({ children }) {
    const pathname = usePathname();
    const authPages = pathname === '/login' || pathname === '/register' || pathname === '/forgot-password';

    return (
        <div className={styles.uiProviders}>
            <Suspense fallback={<NavBarSkeleton />}>
                {!authPages && <NavBar />}
            </Suspense>
            <div className={styles.content}>
                <ScrollShadow hideScrollBar className={styles.scrollShadow}>
                    <main className={`${styles.main} ${!authPages ? styles.paddedMain : ""}`}>
                        {children}
                        <Suspense fallback={<div>Loading...</div>}>
                            <AgeVerificationPopup />
                        </Suspense>
                    </main>
                    {!authPages && (
                        <Suspense fallback={<div>Loading...</div>}>
                            <Footer />
                        </Suspense>
                    )}
                </ScrollShadow>
            </div>
            <Suspense fallback={<BottomBarSkeleton />}>
                {!authPages && <BottomBar />}
            </Suspense>
        </div>
    );
}
