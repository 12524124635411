"use client";
import { FirebaseProvider } from "@/app/context/Firebase";
import { AuthProvider } from "@/app/context/Authentications";
import { SidebarAndNavbarToggleProvider } from "@/app/context/SidebarAndNavbarToggle";
import { lazy, Suspense } from "react";
import MainLoading from "@/components/mainLoading/mainLoading";

const Toaster = lazy(() => import('sonner').then(module => ({ default: module.Toaster })));

export function Providers({
	children,
}) {
	return (
		<FirebaseProvider>
			<SidebarAndNavbarToggleProvider>
				<AuthProvider>
					<Suspense fallback={<></>}>
						<Toaster richColors expand={false} position="bottom-left" />
					</Suspense>
					<MainLoading />
					{children}
				</AuthProvider>
			</SidebarAndNavbarToggleProvider>
		</FirebaseProvider>
	);
}
