import { Skeleton } from "@nextui-org/react";
import styles from "./navBarSkeleton.module.css";

const NavBarSkeleton = () => {
    return (
        <Skeleton className={styles.skeleton}></Skeleton>
    );
};

export default NavBarSkeleton;
