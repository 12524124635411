import { Skeleton } from '@nextui-org/react'
import styles from './bottomBarSkeleton.module.css'


const BottomBarSkeleton = () => {
    return (
        <Skeleton className={styles.skeleton}></Skeleton>
    )
}

export default BottomBarSkeleton    